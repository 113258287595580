import {createStylesParams, StyleParamType} from '@wix/tpa-settings';
import {baseStylesParams, IStylesParams, StylesParamKeys} from '../../baseStylesParams';

const stylesParams: IStylesParams = {
  ...baseStylesParams,
  galleryShowFilters: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
};

export default createStylesParams<StylesParamKeys>(stylesParams);
